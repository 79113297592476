import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SeoServicesMain from "../components/SeoServicesMain"

const SeoServicesPage = () => {
  const keyword = "SEO Services"
  return (
    <Layout>
      <Seo
        noTitlePrefix={true}
        title={`${keyword} | Michigan Web Design | Snappy Web Design`}
        description={`${keyword} should drive leads and sales for your small business. Snappy Web Design makes search optimization easy. Start reaching and engaging more leads.`}
        rating={true}
      />
      <SeoServicesMain keyword={keyword} />
    </Layout>
  )
}

export default SeoServicesPage
